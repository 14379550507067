<template>
  <div class="prospecting">
    <div class="prospecting-top-wrapper">
      <div class="prospecting-top-main">
        <TextInput
          v-model="query"
          left-icon="search"
          placeholder="Find prospects"
          class="prospecting-top-search"
          :button-icon="query ? 'close' : ''"
          :submit-with-button="false"
          @submit="searchByQuery"
          @button-click="() => searchByQuery('')"
        />
        <div class="prospecting-top-btns">
          <Button
            v-if="canWriteProspectingLists"
            text="Create list from results"
            :disabled="!buttonsEnabled"
            :loading="listCreateLoading"
            @click="startListCreate"
          />
          <Button
            text="Export to CSV"
            :disabled="!buttonsEnabled"
            :loading="exportLoading"
            @click="handleExport"
          />
          <Button
            v-if="canWriteIntegrations"
            text="Manage ambassadors"
            @click="() => (showAmbassadorsEdit = true)"
          />
        </div>
      </div>
      <div v-if="filters.length" class="prospecting-top-filters">
        <SimilarCompany
          v-if="filters.length"
          :organisation="similarCompany"
          @submit="searchSimilarCompany"
        />
        <div
          v-if="filters.length"
          class="prospecting-top-filters-divider"
        ></div>
        <FilterDropdown
          v-for="filter in availableFilters"
          :key="filter.id"
          :filter="filter"
          :selected="activeFilters[filter.id] || []"
          empty-text="Unknown"
          @submit="selection => selectFilter(filter.id, selection)"
        >
          <template #item="{ item }">
            <div
              v-if="filter.id === 'ambassadors'"
              class="prospecting-top-filters-item"
            >
              <Avatar v-if="item.key" :user="item.key" fallback-icon="user" />
              {{ item.value }}
            </div>
          </template>
        </FilterDropdown>
      </div>
      <div v-else-if="consumptionLoading" class="prospecting-top-filters">
        <b-skeleton height="2.5rem" width="10rem" />
        <div class="prospecting-top-filters-divider"></div>
        <b-skeleton
          v-for="idx in 3"
          :key="`prospecting-filter-loading-${idx}`"
          height="2.5rem"
          width="10rem"
        />
      </div>
    </div>
    <p class="prospecting-count" :class="{ loading: consumptionLoading }">
      {{ paginatorDataCount }} result{{ paginatorDataCount === 1 ? '' : 's' }}
    </p>
    <div
      ref="prospectstablewrapper"
      :style="maxHeightStyle"
      class="prospecting-table"
      @wheel="handleScroll"
    >
      <Table :items="prospects" :headers="headers" :sortable="false">
        <template #item="{ header, item }">
          <div
            v-if="header.id === 'name'"
            class="prospecting-table-item-name-wrapper"
          >
            <Avatar :user="{ avatar: item.icon, username: item.name }" />
            <div class="prospecting-table-item-name-content">
              <div class="prospecting-table-item-name-content-top">
                {{ header.key(item) }}
                <img
                  v-if="item.linkedin_url"
                  src="@/assets/logo/linkedin.svg"
                  alt=""
                  class="prospecting-table-item-name-content-top-linkedin"
                  @click="() => gotoLinkedIn(item)"
                />
              </div>
              <span
                v-if="item.domain"
                class="prospecting-table-item-name-content-domain"
                @click="() => gotoDomain(item)"
                >{{ item.domain }}</span
              >
            </div>
          </div>
          <p
            v-else-if="header.id === 'domain'"
            class="prospecting-table-item-domain"
            @click="() => gotoDomain(item)"
          >
            {{ header.key(item) }}
          </p>
          <img
            v-else-if="header.id === 'linkedin'"
            src="@/assets/logo/linkedin.svg"
            alt=""
            class="prospecting-table-item-linkedin"
            @click="() => gotoLinkedIn(item)"
          />
          <WarmIntroductions
            v-else-if="header.id === 'warm_intro'"
            :profiles="item.profiles"
            :pitch-loading="gotoPitchLoading === item.uuid"
            @pitch="people => gotoPitch(item, people)"
          />
          <div
            v-else-if="header.id === 'actions'"
            class="prospecting-table-item-actions"
          >
            <Button
              text="Pitch"
              type="white"
              :loading="gotoPitchLoading === item.uuid"
              :disabled="!!gotoPitchLoading && gotoPitchLoading !== item.uuid"
              @click="() => gotoPitch(item)"
            />
            <b-tooltip
              position="is-left"
              label="Find similar companies"
              type="is-dark"
            >
              <Button
                icon="search-star"
                type="light"
                @click="() => searchSimilarCompany(item)"
              />
            </b-tooltip>
          </div>
        </template>
      </Table>
      <div v-if="consumptionLoading" class="prospecting-loading">
        <b-loading active :is-full-page="false" />
      </div>
      <div
        v-if="!consumptionLoading && !prospects.length"
        class="prospecting-status"
        :class="{ errored: loadingError }"
      >
        <img
          :src="
            require(`@/assets/icons/${
              loadingError ? 'check-warning' : 'search'
            }.svg`)
          "
          alt=""
          class="prospecting-status-icon"
        />
        <p class="prospecting-status-text">
          {{
            loadingError
              ? 'Something went wrong while loading prospects, please try again later or contact support'
              : 'No prospects found. Try adjusting your filters or search query.'
          }}
        </p>
        <Button
          v-if="loadingError"
          text="Retry"
          icon="refresh"
          type="white"
          :icon-left="true"
          @click="resetPaginatorConsumer"
        />
      </div>
    </div>
    <AmbassadorsEdit
      :visible="showAmbassadorsEdit"
      @close="() => (showAmbassadorsEdit = false)"
    />
    <EditMeeting
      v-if="!!meetingCreateAccount"
      :visible="!!meetingCreateAccount"
      :force-account="meetingCreateAccount"
      :force-people="meetingCreatePeople"
      @created="handleMeetingCreated"
      @close="closeMeetingCreate"
    />
    <CreateAccountsList
      :visible="listModalOpen"
      :loading="listCreateLoading"
      @close="endListCreate"
      @submit="createList"
    />
  </div>
</template>

<script>
import { PaginatorConsumer } from '@/mixins/PaginatorConsumer'
import {
  getProspectsPaginator,
  getProspectsPage,
  createProspectsList
} from '@/services/prospectingService'
import Avatar from '@c/library/Avatar.vue'
import Button from '@c/library/Button.vue'
import FilterDropdown from '@c/library/FilterDropdown.vue'
import WarmIntroductions from './prospecting/WarmIntroductions.vue'
import Table from '@c/library/Table.vue'
import { mapGetters, mapActions } from 'vuex'
import SimilarCompany from './prospecting/SimilarCompany.vue'
import { createAccount } from '@/services/accountService'
import { getOrganisation } from '@/services/organisationService'
import TextInput from '@c/library/TextInput.vue'
import AmbassadorsEdit from './prospecting/AmbassadorsEdit.vue'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'
import EditMeeting from './meeting/EditMeeting.vue'
import CreateAccountsList from './accounts/CreateAccountsList.vue'

export default {
  name: 'Prospecting',
  components: {
    Button,
    Table,
    Avatar,
    FilterDropdown,
    WarmIntroductions,
    SimilarCompany,
    TextInput,
    AmbassadorsEdit,
    EditMeeting,
    CreateAccountsList
  },
  mixins: [PaginatorConsumer, MaxHeightMixin],
  data: () => ({
    filtersInitialized: false,
    aggregations: {},
    activeFilters: {},
    similarCompany: undefined,
    query: '',
    gotoPitchLoading: '',
    showAmbassadorsEdit: false,
    meetingCreateAccount: undefined,
    meetingCreatePeople: undefined,
    exportLoading: false,
    searchStarted: false,
    listModalOpen: false,
    listCreateLoading: false
  }),
  computed: {
    ...mapGetters([
      'currentWorkspace',
      'showCollectSlides',
      'canUseProspecting',
      'canWriteIntegrations',
      'canWriteProspectingLists'
    ]),
    headers() {
      return [
        {
          id: 'name',
          title: 'Name',
          key: p => p.name
        },
        {
          id: 'headquarters',
          title: 'Headquarters',
          key: p => p.headquarters || 'Unknown'
        },
        {
          id: 'employee_count',
          title: 'Employees',
          key: p => p.employee_count || 'Unknown'
        },
        {
          id: 'industry',
          title: 'Industry',
          key: p => p.industry?.name || 'Unknown'
        },
        {
          id: 'warm_intro',
          title: 'Warm introductions',
          key: p => (p.profiles?.length ? 'Yes' : 'No')
        },
        {
          id: 'actions',
          title: '',
          key: () => ''
        }
      ]
    },
    aggregationsKeys() {
      return Object.keys(this.aggregations)
    },
    filters() {
      return this.aggregationsKeys.map(key => ({
        id: key,
        title: [].includes(key)
          ? {}[key]
          : key.replaceAll('_', ' ').capitalize(),
        options: this.aggregations[key].map(a =>
          key === 'ambassadors'
            ? {
                key: a,
                id: a.uuid,
                value: this.$umodel.full_name(a)
              }
            : {
                key: a
              }
        ),
        multiselect: !['is_account'].includes(key)
      }))
    },
    availableFilters() {
      return this.filters.filter(f => f.options.length)
    },
    prospects() {
      return this.paginatorData || []
    },
    loadingOrDone() {
      return this.consumptionLoading || this.isDone
    },
    buttonsEnabled() {
      return (
        !this.consumptionLoading && this.prospects.length && this.searchStarted
      )
    }
  },
  watch: {
    prospects(val, old) {
      if ((val?.length || 0) > (old?.length || 0))
        this.$nextTick(() => this.checkMaxHeightExtended(true))
    }
  },
  mounted() {
    if (
      !this.currentWorkspace ||
      this.currentWorkspace.uuid !== this.$route.params.workspace_id
    ) {
      this.setCurrentWorkspace(this.$route.params.workspace_id)
    }
    if (!this.canUseProspecting) {
      this.$router.push({ name: 'home' })
    }
  },
  methods: {
    ...mapActions(['setCurrentWorkspace']),
    getMaxHeightElement() {
      return this.$refs.prospectstablewrapper
    },
    async loadFiltersFromQuery() {
      const query = this.$route.query
      try {
        this.query = query.query
        this.similarCompany = query.organisation_id
          ? await getOrganisation({ organisation_id: query.organisation_id })
          : undefined
      } catch (e) {
        this.$console.debug('Failed to load similar company from query', e)
        this.$toast.error(e, 'loading similar company')
      }
      delete query.query
      delete query.organisation_id
      this.activeFilters = {
        ...Object.keys(query).reduce(
          (acc, key) => ({
            ...acc,
            [key]: Array.isArray(query[key]) ? query[key] : [query[key]]
          }),
          {}
        )
      }
    },
    checkMaxHeightExtended(check_scroll = false) {
      this.checkMaxHeight()
      if (check_scroll) this.$nextTick(this.handleScroll)
    },
    async setupPaginatorConsumer() {
      try {
        this.loadingError = false
        this.consumptionLoading = true
        if (!this.filtersInitialized) {
          await this.loadFiltersFromQuery()
          this.filtersInitialized = true
        }
        this.paginator = await this.getPaginator()
        this.pages = await this.getPages()
        await this.loadPage(true)
        this.paginatorDataCount = await this.getCount()
      } catch (e) {
        this.loadingError = true
        this.$console.warning('Failed to setup paginator consumer')
        this.$toast.error(e, 'loading prospects')
      } finally {
        this.consumptionLoading = false
      }
    },
    getPaginator() {
      this.searchStarted =
        !!this.query || Object.keys(this.activeFilters).length
      return getProspectsPaginator({
        workspace_id: this.currentWorkspace.uuid,
        query: this.query,
        ...Object.keys(this.activeFilters).reduce(
          (acc, key) => ({
            ...acc,
            ...(this.activeFilters[key]?.length
              ? {
                  [key]: ['is_account'].includes(key)
                    ? this.activeFilters[key].join(',')
                    : this.activeFilters[key]
                }
              : {})
          }),
          {}
        ),
        ...(this.similarCompany
          ? {
              organisation_id: this.similarCompany.uuid
            }
          : {}),
        page_size: 20,
        callback: res => {
          this.aggregations = res.data.aggregations
        }
      })
    },
    handleScroll() {
      const el = this.$refs.prospectstablewrapper
      if (
        el.scrollHeight - el.offsetHeight - el.scrollTop < 100 &&
        !this.consumptionLoading &&
        !this.isDone
      ) {
        this.loadPage()
      }
    },
    searchByQuery(query = undefined) {
      if (query !== undefined) this.query = query
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          query: this.query || undefined
        }
      })
      this.resetPaginatorConsumer()
    },
    selectFilter(id, options) {
      this.activeFilters[id] = options
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          ...this.activeFilters
        }
      })
      this.resetPaginatorConsumer()
    },
    searchSimilarCompany(organisation) {
      this.similarCompany = organisation
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          organisation_id: organisation?.uuid
        }
      })
      this.resetPaginatorConsumer()
    },
    async gotoPitch(item, people = []) {
      try {
        this.gotoPitchLoading = item.uuid
        const account = await createAccount({
          workspace_id: this.$route.params.workspace_id,
          linkedin_url: item.linkedin_url
        })
        const audience = people.map(p => ({
          avatar: p.avatar,
          name: this.$umodel.full_name(p),
          linkedin_url: p.url.replaceAll('www.', ''),
          role: p.role
        }))
        this.meetingCreatePeople = audience
        this.meetingCreateAccount = account
      } catch (e) {
        this.$console.debug('Error going to pitch from prospecting', e)
        this.$toast.error(e, 'starting meeting')
      } finally {
        this.gotoPitchLoading = ''
      }
    },
    closeMeetingCreate() {
      this.meetingCreateAccount = undefined
      this.meetingCreatePeople = undefined
    },
    async handleMeetingCreated({ meeting, templates, settings }) {
      this.closeMeetingCreate()
      localStorage.setItem('meetingOutputTemplates', JSON.stringify(templates))
      let props = localStorage.getItem('meetingOutputProps')
      props = props ? JSON.parse(props) : {}
      localStorage.setItem(
        'meetingOutputProps',
        JSON.stringify({ ...props, ...settings })
      )
      this.$router.push({
        name: 'meeting-outputs',
        params: {
          workspace_id: this.$route.params.workspace_id,
          meeting_id: meeting.uuid
        }
      })
    },
    async handleExport() {
      this.exportLoading = true
      try {
        const data = await getProspectsPage({
          workspace_id: this.currentWorkspace.uuid,
          query: this.query,
          ...Object.keys(this.activeFilters).reduce(
            (acc, key) => ({
              ...acc,
              ...(this.activeFilters[key]?.length
                ? {
                    [key]: ['is_account'].includes(key)
                      ? this.activeFilters[key].join(',')
                      : this.activeFilters[key]
                  }
                : {})
            }),
            {}
          ),
          ...(this.similarCompany
            ? {
                organisation_id: this.similarCompany.uuid
              }
            : {}),
          export_filetype: 'csv'
        })
        const blob = new Blob([data], { type: 'text/csv' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        const m = this.$moment(new Date())
        a.download = `uman_prospects_${m.format('DD-MM-YYYY-HH-mm-ss')}.csv`
        a.click()
      } catch (e) {
        this.$console.debug('Prospects export error', e)
        this.$toast.danger(e, 'exporting prospects')
      } finally {
        this.exportLoading = false
      }
    },
    gotoDomain(item) {
      let domain = item.domain
      if (!domain.startsWith('http')) {
        domain = `http://${domain}`
      }
      window.open(domain, '_blank')
    },
    gotoLinkedIn(item) {
      window.open(item.linkedin_url, '_blank')
    },
    startListCreate() {
      this.listModalOpen = true
    },
    endListCreate() {
      this.listModalOpen = false
    },
    async createList({ name, assignees, fields }) {
      try {
        this.listCreateLoading = true
        const list = await createProspectsList({
          workspace_id: this.currentWorkspace.uuid,
          search: {
            query: this.query,
            ...Object.keys(this.activeFilters).reduce(
              (acc, key) => ({
                ...acc,
                ...(this.activeFilters[key]?.length
                  ? {
                      [key]: ['is_account'].includes(key)
                        ? this.activeFilters[key].join(',')
                        : this.activeFilters[key]
                    }
                  : {})
              }),
              {}
            ),
            ...(this.similarCompany
              ? {
                  organisation_id: this.similarCompany.uuid
                }
              : {})
          },
          name,
          fields,
          assignee_ids: assignees.map(a => a.uuid)
        })
        this.endListCreate()
        this.$router.push({
          name: 'accounts',
          params: {
            workspace_id: this.currentWorkspace.uuid
          },
          query: {
            list: list.uuid
          }
        })
      } catch (e) {
        this.$console.debug('Error creating list from prospects', e)
        this.$toast.error(e, 'creating list')
      } finally {
        this.listCreateLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.prospecting {
  &-top {
    &-main {
      flex: 1;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 0.5rem;
      justify-content: space-between;
    }

    &-wrapper {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;
      padding: 2.5rem;
    }

    &-search {
      width: min(30rem, 100%);
    }

    &-filters {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 0.5rem;

      &-divider {
        margin: 0 0.5rem;
        min-height: 1.75rem;
        border-left: 1px solid rgba(0, 0, 0, 0.16);
      }

      &-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
      }
    }

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &-count {
    padding: 0 2.5rem 0.5rem;
    color: #60666b;

    &.loading {
      opacity: 0;
    }
  }

  &-loading {
    position: relative;
    min-height: 10rem;
  }

  &-status {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1.5rem;
    color: #60666b;

    &.errored {
      flex-flow: column nowrap;
    }

    &-icon {
      height: 1.75rem;
      filter: invert(41%) sepia(7%) saturate(451%) hue-rotate(165deg)
        brightness(90%) contrast(87%);
    }

    &-text {
      font-size: 1.25rem;
    }
  }

  &-table {
    overflow-y: auto;
    padding: 0 2.5rem;

    &-item {
      &-name {
        &-wrapper {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          gap: 1rem;
          font-size: 1.15rem;
          font-weight: 600;
          width: 20rem;
        }

        &-content {
          display: flex;
          flex-flow: column nowrap;
          &-top {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 0.5rem;

            &-linkedin {
              height: 1.1rem;
              cursor: pointer;
            }
          }

          &-domain {
            cursor: pointer;
            color: $primary;
            font-weight: 300;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      &-domain {
        cursor: pointer;
        color: $primary;

        &:hover {
          text-decoration: underline;
        }
      }

      &-linkedin {
        cursor: pointer;
      }

      &-actions {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 1rem;
        width: 100%;
        justify-content: flex-end;
      }
    }
  }
}

::v-deep .b-skeleton {
  width: fit-content;
  margin: 0 !important;
}
</style>
