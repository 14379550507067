var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "b-modal",
        {
          attrs: { active: _vm.visible, "can-cancel": ["escape", "outside"] },
          on: { close: _vm.close }
        },
        [
          _c("div", { staticClass: "create-list" }, [
            _c(
              "div",
              { staticClass: "create-list-header" },
              [
                _c("p", { staticClass: "create-list-header-title" }, [
                  _vm._v(" Create accounts list ")
                ]),
                _c("Button", {
                  attrs: { icon: "close", type: "grey", size: "xs" },
                  on: { click: _vm.close }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "create-list-content" },
              [
                _c("TextInput", {
                  attrs: { title: "Name", placeholder: "Add list name" },
                  model: {
                    value: _vm.name,
                    callback: function($$v) {
                      _vm.name = $$v
                    },
                    expression: "name"
                  }
                }),
                _c("ORIOwnersEdit", {
                  attrs: {
                    title: "Assignees",
                    "default-owners": [_vm.currentWorkspaceMember]
                  },
                  on: {
                    submit: function(a) {
                      return (_vm.assignees = a)
                    }
                  }
                }),
                _c(
                  "div",
                  { staticClass: "create-list-content-fields" },
                  [
                    _c("p", { staticClass: "create-list-content-header" }, [
                      _vm._v("Custom fields")
                    ]),
                    _vm._l(_vm.fields, function(field, idx) {
                      return _c(
                        "div",
                        {
                          key: "customfield-" + idx,
                          staticClass: "create-list-content-fields-field"
                        },
                        [
                          _c("TextInput", {
                            staticClass:
                              "create-list-content-fields-field-name",
                            attrs: {
                              value: field.name,
                              placeholder: "Field name"
                            },
                            on: {
                              input: function(n) {
                                return _vm.handleFieldInput(idx, "name", n)
                              }
                            }
                          }),
                          _c("TextArea", {
                            staticClass:
                              "create-list-content-fields-field-prompt",
                            attrs: {
                              value: field.prompt,
                              placeholder: "Instructions for enrichment"
                            },
                            on: {
                              input: function(n) {
                                return _vm.handleFieldInput(idx, "prompt", n)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("Button", {
                            attrs: { icon: "bin", type: "white", size: "xs" },
                            on: {
                              click: function() {
                                return _vm.handleFieldRemove(idx)
                              }
                            }
                          })
                        ],
                        1
                      )
                    }),
                    _c("Button", {
                      attrs: {
                        text: "Add custom field",
                        size: "xs",
                        type: "light",
                        "full-width": true
                      },
                      on: { click: _vm.addCustomField }
                    })
                  ],
                  2
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "create-list-footer" },
              [
                _c("Button", {
                  attrs: {
                    text: "Cancel",
                    type: "white",
                    disabled: _vm.loading
                  },
                  on: { click: _vm.close }
                }),
                _c("Button", {
                  attrs: {
                    text: "Create list",
                    disabled: !_vm.name || !_vm.assignees.length,
                    loading: _vm.loading
                  },
                  on: { click: _vm.submit }
                })
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }