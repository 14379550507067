var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "similar-company" },
    [
      _c(
        "div",
        {
          staticClass: "similar-company-trigger",
          class: { selected: !!_vm.organisation },
          on: { click: _vm.handleTriggerClick }
        },
        [
          _c("img", {
            staticClass: "similar-company-trigger-icon",
            attrs: { src: require("@/assets/icons/search-star.svg"), alt: "" }
          }),
          _vm._v(
            " " +
              _vm._s(
                "Similar company " +
                  (_vm.organisation ? ": " : "") +
                  (_vm.organisation ? _vm.organisation.name : "")
              ) +
              " "
          )
        ]
      ),
      _c(
        "b-modal",
        {
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("div", { staticClass: "similar-company-modal" }, [
            _c(
              "div",
              { staticClass: "similar-company-modal-header" },
              [
                _vm._v(" Find similar company "),
                _c("img", {
                  staticClass: "similar-company-modal-header-icon",
                  attrs: {
                    src: require("@/assets/icons/search-star.svg"),
                    alt: ""
                  }
                }),
                _c("Button", {
                  attrs: { icon: "close", type: "grey", size: "xs" },
                  on: { click: _vm.closeModal }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "similar-company-modal-content" },
              [
                _c("OrganisationSearch", {
                  attrs: {
                    organisation: _vm.selection,
                    "create-account": false,
                    "allow-custom": false,
                    "include-suggestions": false
                  },
                  on: {
                    input: function(sel) {
                      return (_vm.selection = sel)
                    },
                    remove: function() {
                      return (_vm.selection = undefined)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "similar-company-modal-footer" },
              [
                _c("Button", {
                  attrs: { text: "Cancel", type: "white" },
                  on: { click: _vm.closeModal }
                }),
                _c("Button", {
                  attrs: { text: "Search" },
                  on: { click: _vm.submit }
                })
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }