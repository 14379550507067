<template>
  <div class="similar-company">
    <div
      class="similar-company-trigger"
      :class="{ selected: !!organisation }"
      @click="handleTriggerClick"
    >
      <img
        src="@/assets/icons/search-star.svg"
        alt=""
        class="similar-company-trigger-icon"
      />
      {{
        `Similar company ${organisation ? ': ' : ''}${
          organisation ? organisation.name : ''
        }`
      }}
    </div>
    <b-modal v-model="showModal">
      <div class="similar-company-modal">
        <div class="similar-company-modal-header">
          Find similar company
          <img
            src="@/assets/icons/search-star.svg"
            alt=""
            class="similar-company-modal-header-icon"
          />
          <Button icon="close" type="grey" size="xs" @click="closeModal" />
        </div>
        <div class="similar-company-modal-content">
          <OrganisationSearch
            :organisation="selection"
            :create-account="false"
            :allow-custom="false"
            :include-suggestions="false"
            @input="(sel) => (selection = sel)"
            @remove="() => (selection = undefined)"
          />
        </div>
        <div class="similar-company-modal-footer">
          <Button text="Cancel" type="white" @click="closeModal" />
          <Button text="Search" @click="submit" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import OrganisationSearch from '../organisations/OrganisationSearch.vue'

export default {
  name: 'SimilarCompany',
  components: {
    Button,
    OrganisationSearch
  },
  props: {
    organisation: {
      type: Object,
      default: undefined
    }
  },
  data: () => ({
    showModal: false,
    query: '',
    selection: undefined
  }),
  methods: {
    handleTriggerClick() {
      if (this.organisation) {
        this.$emit('submit', undefined)
      } else {
        this.showModal = true
      }
    },
    closeModal() {
      this.selection = undefined
      this.showModal = false
    },
    submit() {
      this.$emit('submit', this.selection)
      this.closeModal()
    }
  }
}
</script>

<style lang="scss" scoped>
.similar-company {
  &-trigger {
    min-width: 10rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
    border: 1px solid rgba(#000, 0.08);
    cursor: pointer;

    &:hover {
      background: rgba(#000, 0.04);
    }

    &.selected {
      background: $primary;
      color: #fff;
      border: 1px solid $primary;

      &:hover {
        background: darken($primary, 5%);
        border: 1px solid darken($primary, 5%);
      }

      & > .similar-company-trigger-icon {
        filter: brightness(0) invert(1);
      }
    }

    &-icon {
      height: 1.2rem;
      filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
        saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);
    }
  }

  &-modal {
    width: min(45rem, 90vw);
    background: white;
    border-radius: 6px;

    &-header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.5rem;
      padding: 1.5rem;
      font-weight: 600;
      border-bottom: 1px solid rgba(#000, 0.08);

      &-icon {
        height: 1.35rem;
        margin-right: auto;
      }
    }

    &-content {
      padding: 1.5rem;
    }

    &-footer {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      gap: 0.5rem;
      padding: 1.5rem;
      border-top: 1px solid rgba(#000, 0.08);
    }
  }
}

::v-deep .modal-content {
  width: unset !important;
}
</style>
