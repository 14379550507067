<template>
  <b-modal
    v-if="visible"
    :active="visible"
    :can-cancel="['escape', 'outside']"
    @close="close"
  >
    <div class="create-list">
      <div class="create-list-header">
        <p class="create-list-header-title">
          Create accounts list
        </p>
        <Button icon="close" type="grey" size="xs" @click="close" />
      </div>
      <div class="create-list-content">
        <TextInput v-model="name" title="Name" placeholder="Add list name" />
        <ORIOwnersEdit
          title="Assignees"
          :default-owners="[currentWorkspaceMember]"
          @submit="a => (assignees = a)"
        />
        <div class="create-list-content-fields">
          <p class="create-list-content-header">Custom fields</p>
          <div
            v-for="(field, idx) in fields"
            :key="`customfield-${idx}`"
            class="create-list-content-fields-field"
          >
            <TextInput
              :value="field.name"
              placeholder="Field name"
              class="create-list-content-fields-field-name"
              @input="n => handleFieldInput(idx, 'name', n)"
            />
            <TextArea
              :value="field.prompt"
              placeholder="Instructions for enrichment"
              class="create-list-content-fields-field-prompt"
              @input="n => handleFieldInput(idx, 'prompt', n)"
            />
            <Button
              icon="bin"
              type="white"
              size="xs"
              @click="() => handleFieldRemove(idx)"
            />
          </div>
          <Button
            text="Add custom field"
            size="xs"
            type="light"
            :full-width="true"
            @click="addCustomField"
          />
        </div>
      </div>
      <div class="create-list-footer">
        <Button text="Cancel" type="white" :disabled="loading" @click="close" />
        <Button
          text="Create list"
          :disabled="!name || !assignees.length"
          :loading="loading"
          @click="submit"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import ORIOwnersEdit from '@/views-v2/ori/ORIOwnersEdit.vue'
import TextInput from '@c/library/TextInput.vue'
import TextArea from '@c/library/TextArea.vue'
import Button from '@c/library/Button.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateAccountsList',
  components: { ORIOwnersEdit, TextInput, TextArea, Button },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    name: '',
    assignees: [],
    fields: [
      {
        name: '',
        prompt: ''
      }
    ]
  }),
  computed: {
    ...mapGetters(['currentWorkspaceMember'])
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async submit() {
      this.$emit('submit', {
        name: this.name,
        assignees: this.assignees,
        fields: this.fields.filter(f => f.name && f.prompt)
      })
    },
    addCustomField() {
      this.fields.push({
        name: '',
        prompt: ''
      })
    },
    handleFieldInput(idx, key, value) {
      this.fields[idx][key] = value
      if (idx === this.fields.length - 1) this.addCustomField()
    },
    handleFieldRemove(idx) {
      this.fields.splice(idx, 1)
      if (!this.fields.length) this.addCustomField()
    }
  }
}
</script>

<style lang="scss" scoped>
.create-list {
  background: white;
  border-radius: 6px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.04);
  max-height: calc(100vh - 7rem);
  display: flex;
  flex-flow: column nowrap;
  width: min(45rem, calc(100vw - 2rem));
  margin-bottom: 4rem;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid rgba(#000, 0.08);

    &-title {
      font-size: 1.5rem;
      font-weight: 700;
    }

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &-content {
    flex: 1;
    overflow: auto;
    padding: 1.5rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;

    &-header {
      font-weight: 600;
    }

    &-fields {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;

      &-field {
        display: flex;
        flex-flow: row nowrap;
        gap: 0.5rem;

        &-name {
          width: 30%;
        }

        &-prompt {
          flex: 1;
        }
      }
    }
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    padding: 1.5rem;
    border-top: 1px solid rgba(#000, 0.08);
    gap: 0.5rem;
  }
}

::v-deep .modal-content {
  width: unset !important;
  max-width: unset !important;
}
</style>
